<!-- src/components/WindowsToolbar.vue -->
<template>
    <div class="toolbar">
      <div class="w-40 toolbar-item window-item">
        <button class=" " style="  ">
            <img alt="windows" src="@/assets/windows95.png" width="30px" height="10px" />
        </button>
      </div>
      <div class="toolbar-item window-item">
        <button>Roadmap</button>
      </div>
      <div class="toolbar-item window-item">
        <button>Utility</button>
      </div>
      <div class="spacer"></div>

      <div class="toolbar-item time">
        <button>
        <span class="">{{ currentTime }}</span></button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentTime: ''
      };
    },
    created() {
      this.updateTime();
      setInterval(this.updateTime, 1000); // Update time every second
    },
    methods: {
      updateTime() {
        const now = new Date();
        this.currentTime = now.toLocaleTimeString();
      }
    }
  };
  </script>
  
  <style scoped>
  .toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #c0c0c0; /* Classic Windows grey */
    border-top: 3px solid #e7e7e7;
    padding: 0;
    box-sizing: border-box;
  }
  
  .toolbar-item button {
    background-color: #c0c0c0;
    border: none;
    padding: 15px 20px;
    font-size: 13px;
    margin-right: 0;
    font-weight: 600; /* Making font semi-bold */
  }

  
  .toolbar-item.window-item + .toolbar-item.window-item {
    margin-left: 20px; /* Added space between first button and the next two */
  }
  
  .toolbar-item:first-child::after {
    content: "";
    display: block;
    width: 1px;
    height: 50%; /* Adjust height based on your aesthetic preference */
    background-color: #000;
    margin: auto 20px; /* Space before the vertical line */
  }
  
  .spacer {
    flex-grow: 1;
  }
  
  .time {
    background-color: transparent;
    padding-right: 10px;
    font-size: large;
    color: #000;
  }
  
  img {
    height: 23px;


  }
  </style>
  

  